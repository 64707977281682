<template>
<section id="dashboard-analytics">
    <b-row class="match-height hidden">
        <b-col lg="3" md="6">
            <statistic-card-with-area-chart v-if="data.subscribersGained" icon="ListIcon" :loader="isLoading" color="success" :statistic="data.subscribersGained.analyticsData.subscribers | formatNumber" statistic-title="Total Response" :chart-data="data.subscribersGained.series" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-with-area-chart v-if="data.subscribersGained" icon="UsersIcon" :loader="isLoading" :statistic="data.subscribersGained.analyticsData.subscribers | formatNumber" statistic-title="Total Contacts" :chart-data="data.subscribersGained.series" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-with-area-chart v-if="data.messageSent" icon="PackageIcon" :loader="isLoading" color="warning" :statistic="data.messageSent.analyticsData.orders | formatNumber" statistic-title="Total Surveys" :chart-data="data.messageSent.series" />
        </b-col>
        <b-col lg="3" sm="6">
            <statistic-card-with-area-chart v-if="data.messageSent" icon="CheckIcon" :loader="isLoading" color="success" :statistic="data.messageSent.analyticsData.orders | formatNumber" statistic-title="Completion Rate" :chart-data="data.messageSent.series" />
        </b-col>
    </b-row>
    <b-row class="match-height">
        <!-- <b-col lg="12">
            <echart-area />
        </b-col> -->
        <b-col lg="12">
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table :columns="columns" :rows="rows" :search-options="{
        enabled: true,
        externalQuery: searchTerm }" :pagination-options="{
        enabled: true,
        perPage:pageLength
      }">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: Survey -->
                    <span v-if="props.column.field === 'surveyName'" class="text-nowrap">

                        <b-link :to="{ name: 'survey-view', params: { id: props.row.appId } }" class="font-weight-bold d-block text-nowrap">{{ props.row.surveyName }}</b-link>
                        <small>Start {{props.row.startDate | formatDate}} - End {{props.row.stopDate | formatDate}}</small>
                    </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                        <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status | formartStatus }}
                        </b-badge>
                    </span>

                    <!-- Column: completionData -->

                    <!-- Column: Action survey-view -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                                </template>
                                <b-dropdown-item :to="{ name: 'survey-view', params: { id: props.row.appId } }" >
                                    <feather-icon icon="EyeIcon" class="mr-50" />
                                    <span>View</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="sendSurvey(props.row.surveySettingID,props.row.appId )" v-show="props.row.status == 1">
                                    <feather-icon icon="Edit2Icon" class="mr-50" />
                                    <span>Send Survey</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="stopSurvey(props.row.appId)" v-show="props.row.status == 1">
                                    <feather-icon icon="TrashIcon" class="mr-50" />
                                    <span>Stop</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-col>

    </b-row>

</section>
</template>

<script>
import {
    BRow,
    BCol,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText
} from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EchartArea from './EchartSurveyArea.vue'
import {
    kFormatter
} from '@core/utils/filter'
import store from '@/store/index'
import $ from 'jquery'
import moment from "moment";
import numeral from "numeral";
import {
    VueGoodTable
} from 'vue-good-table'

export default {
    components: {
        BRow,
        BCol,
        BAvatar,
        BLink,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        StatisticCardWithAreaChart,
        EchartArea,
        VueGoodTable,
        BProgress,
        BCardText
    },
    data() {
        return {
            data: {
                accountConsumption: {
                    series: [{
                        name: 'Consumption',
                        data: [908, 600, 2003, 800, 508, 200, 1200],
                    }, ],
                    analyticsData: {
                        balance: 0,
                        currency: ""
                    },
                },
                subscribersGained: {
                    series: [{
                        name: 'Contacts',
                        data: [],
                    }, ],
                    analyticsData: {
                        subscribers: 0,
                    },
                },
                messageSent: {
                    series: [{
                        name: 'Message',
                        data: [],
                    }, ],
                    analyticsData: {
                        orders: 0,
                    },
                },
                avgMessages: {
                    recipients: 0,
                    percent: '',
                    delivered: 0,
                    failed: 0,
                    pending: 0,
                    sent: 0,
                    salesBar: {
                        series: [{
                            name: 'SMS Delivered',
                            data: [],
                        }, ],
                    },
                },
                supportTracker: {
                    title: 'Support Tracker',
                    lastDays: ['Last 28 Days', 'Last Month', 'Last Year'],
                    totalTicket: 163,
                    newTicket: 29,
                    openTicket: 63,
                    responseTime: 1,
                    supportTrackerRadialBar: {
                        series: [83],
                    },
                },
                timeline: {
                    step1: {
                        title: '12 Invoices have been paid',
                        subtitle: 'Invoices have been paid to the company.',
                        img: require('@/assets/images/icons/json.png'),
                        fileName: 'data.json',
                        duration: '12 min ago',
                    },
                    step2: {
                        title: 'Client Meeting',
                        subtitle: 'Project meeting with john @10:15am',
                        avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                        avatarName: 'John Doe (Client)',
                        occupation: 'CEO of Infibeam',
                        duration: '45 min ago',
                    },
                    step3: {
                        title: 'Create a new project for client',
                        subtitle: 'Add files to new design folder',
                        duration: '2 day ago',
                        avatars: [{
                                userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                                name: 'Billy Hopkins'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
                                name: 'Amy Carson'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
                                name: 'Brandon Miles'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
                                name: 'Daisy Weber'
                            },
                            {
                                userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
                                name: 'Jenny Looper'
                            },
                        ],
                    },
                    step4: {
                        title: 'Create a new project for client',
                        duration: '5 day ago',
                        subtitle: 'Add files to new design folder',
                    },
                },
                salesChart: {
                    series: [{
                            name: 'Sales',
                            data: [90, 50, 86, 40, 100, 20],
                        },
                        {
                            name: 'Visit',
                            data: [70, 75, 70, 76, 20, 85],
                        },
                    ],
                },
                appDesign: {
                    date: '03 Sep, 20',
                    title: 'App design',
                    subtitle: 'You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design',
                    teams: [{
                            name: 'Figma',
                            color: 'light-warning'
                        },
                        {
                            name: 'Wireframe',
                            color: 'light-primary'
                        },
                    ],
                    members: [{
                            img: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
                            color: 'primary'
                        },
                        {
                            text: 'PI',
                            color: 'light-danger'
                        },
                        {
                            img: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
                            color: 'primary'
                        },
                        {
                            img: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
                            color: 'primary'
                        },
                        {
                            text: 'AL',
                            color: 'light-secondary'
                        },
                    ],
                    planing: [{
                            title: 'Due Date',
                            subtitle: '12 Apr, 21'
                        },
                        {
                            title: 'Budget',
                            subtitle: '$49251.91'
                        },
                        {
                            title: 'Cost',
                            subtitle: '$840.99'
                        },
                    ],
                },

            },
            avgMessages: {
                recipients: 0,
                percent: '',
                startDate: "",
                endDate: "",
                delivered: 0,
                failed: 0,
                sent: 0,
                salesBar: {
                    series: [],
                },
            },
            isLoading: false,
            isLoadingAccount: false,
            showButton: true,
            pageLength: 10,
            dir: false,
            columns: [{
                    label: 'Survey Name',
                    field: 'surveyName',
                },
                {
                    label: 'Channel',
                    field: 'chSource',
                },

                {
                    label: 'Status',
                    field: 'status',
                },

                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            status: [{
                    1: 'Active',
                    2: 'Pending',
                    3: 'Rejected',
                },
                {
                    1: 'light-success',
                    3: 'light-danger',
                    2: 'light-warning',
                }
            ],

        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                1: 'light-success',
                3: 'light-danger',
                2: 'light-warning',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
    },
    mounted() {
        this.endDate = moment().format("YYYY-MM-DD")
        this.startDate = moment().subtract(7, 'd').format('YYYY-MM-DD');

        this.getSummaryData()
        this.getLastMessage()
        this.getSummaryList()
        this.getSurveyApp()
    },

    methods: {
        sendSurvey(surveySettingID, appID) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to send this survey', {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    let vm = this;
                    vm.isLoading = true
                    $.post({
                        url: store.state.rootUrl + 'survey/v1/send_sms/'+surveySettingID,
                        post: "POST",
                        headers: {
                            "X-Authorization-Key": vm.$cookies.get("token"),
                            "X-Requested-With": "XMLHttpRequest",
                            "Content-Type": "application/json",
                        },
                        data: JSON.stringify({
                            "app_id":appID
                        }),
                        success: function (response, status, jQxhr) {
                            vm.isLoading = false
                            $('#editUser').html(' Save Changes');
                            if (response.data.code != 200) {
                              
                            } else {
                                vm.getSurveyApp()
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'EditIcon',
                                        variant: 'success',
                                    },
                                })
                            }

                        },
                        error: function (jQxhr, status, error) {
                            vm.isLoading = false
                            vm.dismissCountDown = vm.dismissSecs
                            $('#editUser').html(' Save Changes');
                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                vm.errorMessage = statustext + " " + errorMessage;
                            }
                        }
                    });
                })
        },
        stopSurvey(appID) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to stop this survey', {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    let vm = this;
                    vm.isLoading = true
                    $.post({
                        url: store.state.rootUrl + 'survey/v1/edit/'+appID,
                        post: "POST",
                        headers: {
                            "X-Authorization-Key": vm.$cookies.get("token"),
                            "X-Requested-With": "XMLHttpRequest",
                            "Content-Type": "application/json",
                        },
                        data: JSON.stringify({
                            status: 2,
                        }),
                        success: function (response, status, jQxhr) {
                            vm.isLoading = false
                            $('#editUser').html(' Save Changes');
                            if (response.data.code != 200) {
                              
                            } else {
                                vm.getSurveyApp()
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'EditIcon',
                                        variant: 'success',
                                    },
                                })
                            }

                        },
                        error: function (jQxhr, status, error) {
                            vm.isLoading = false
                            vm.dismissCountDown = vm.dismissSecs
                            $('#editUser').html(' Save Changes');
                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                vm.errorMessage = statustext + " " + errorMessage;
                            }
                        }
                    });
                })
        },
        viewStats(val) {
            let vm = this
            vm.$router.push({
                name: 'survey-view',
                params: {
                    id: val
                }
            });
        },
        kFormatter,
        getSummaryData() {
            let vm = this
            vm.isLoadingAccount = true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/wallet',
                type: "get",
                async: true,
                data: {
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function (response, status, jQxhr) {
                    vm.isLoadingAccount = false
                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.data.accountConsumption.analyticsData.balance = 0
                    } else {
                        let result = response.data.data;
                        vm.data.accountConsumption.analyticsData.balance = result.balance
                        vm.data.accountConsumption.analyticsData.currency = result.currency

                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoadingAccount = false
                    vm.rows = []
                    console.log(jQxhr.responseJSON.data.code)
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                }
            });

        },
        getLastMessage() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_usage',
                type: "get",
                async: true,
                data: {
                    limit: 1,
                    status: 400,
                },
                crossDomain: true,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {
                        var smsData = response.data.data.data[0];
                        vm.avgMessages.recipients = smsData.recipients
                        vm.avgMessages.percent = Number(smsData.delivery_percent).toFixed(2)
                        vm.avgMessages.delivered = smsData.delivered
                        vm.avgMessages.failed = smsData.failed
                        vm.avgMessages.sent = smsData.sent
                        vm.avgMessages.pending = smsData.pending
                        vm.avgMessages.salesBar.series.push({
                            name: 'SMS Delivered',
                            data: [smsData.delivered],
                        })

                        //console.log(JSON.stringify(vm.data.avgMessages))
                    }
                },
                error: function (jQxhr, status, error) {

                }
            });
        },
        getSummaryList() {
            let vm = this
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'account/v1/view/dashboard_stats',
                type: "get",
                async: true,
                data: {
                    start: vm.startDate,
                    end: vm.endDate,
                },
                crossDomain: true,
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false

                    if (response.data.code == 200) {
                        vm.data.subscribersGained.analyticsData.subscribers = response.data.data.contacts
                        vm.data.messageSent.analyticsData.orders = response.data.data.sms_sent

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false

                }
            });
        },
        getSurveyApp() {
            let vm = this;
            vm.isLoading = true
            $.get({
                url: store.state.rootUrl + 'survey/v1/view/all',
                type: "get",
                async: true,
                data: {
                    limit: 50,
                },
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    if (response.data.code != 200) {
                        vm.$swal({
                            title: 'Error!',
                            text: response.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    } else {

                        vm.rows = response.data.data.data

                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                }
            });
        }
    },
    filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },
        formartStatus(value) {

            if (value == 1) {
                return 'Active'
            } else if (value == 2) {
                return 'Pending'
            } else {
                return 'Rejected'
            }
        },
        formatDate: function (value) {
            if (!value) return ''
            return moment(value).fromNow()
        }

    }
}
</script>
